import React from "react";
import './Footer.css';


const GlobalFooter = () => {
return (
    <div>
        <div class="footer-dark">
            <footer>
                <div class="footer-container">
                    <div class="row">
                        <div class="col-sm-6 col-md-3 item">
                            <ul>
                                <li><a href="https://graspdp.com">Register</a></li>
                            </ul>
                        </div>
                    </div>
                    <p class="copyright">Powered by &copy; Grasp</p>
                    <p class="sign">a technology company</p>
                </div>
            </footer>
        </div>
    </div>
);
};
export default GlobalFooter;
