import request from "umi-request";

export async function authenticate(email, password) {
    let url = `${process.env.REACT_APP_AUTH_ENDPOINT}`;
    let data = {
        "email": email,
        "password": password,
    };

    return request(url, {
        method: 'POST',
        data: data,
        requestType: "form",
    });
}