import {BrowserRouter, Route, Routes} from 'react-router-dom';
import './App.css';
import DownloadButton from './components/DownloadButton';
import GlobalFooter from './components/Footer';

function App() {
    return (
        <BrowserRouter>
            <div>
                <Routes>
                    <Route path='/download' Component={DownloadButton}/>
                </Routes>
                <GlobalFooter />
            </div>
        </BrowserRouter>
    );
}

export default App;
