import request from "umi-request";


export async function download(token, secrectId, accessKey) {
    let url = `${process.env.REACT_APP_API_DOWNLOAD}`;
    let params = {
        "secretId": secrectId,
        "accessKey": accessKey,
    };
    let headers = {
        "Authorization": `Bearer ${token}`,
    };

    return request(url, {
        method: 'GET',
        params: params,
        headers: headers,
    });
}