import React, {useState} from 'react';
import {message} from 'antd';
import {useLocation} from 'react-router-dom';

import {ProForm, ProFormText} from '@ant-design/pro-components';

import Login from './Login';
import {authenticate} from '../services/auth';
import {download} from '../services/api';

const DownloadButton = () => {
    const [authenticated, setAuthenticated] = useState(false);
    const [token, setToken] = useState(null);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const secretId = searchParams.get("secretId");
    const accessKey = searchParams.get("accessKey");

    const handleLogin = async (values) => {
        const email = values.email;
        const password = values.password;

        try {
            // Send a request to authenticate the user's credentials
            const response = await authenticate(email, password);
            const token = response.access_token;
            setAuthenticated(true);
            setToken(token);
        } catch (error) {
            message.error("Login failed. Please try again.")
        }
    };


    const handleDownload = async (values) => {
        try {
            // Send a request to download the content
            const response = await download(token, values.secretId, values.accessKey);      // Create a URL for the downloaded content and open it in a new tab
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'secret');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error(error);
        }
    };

    if (!authenticated) {
        // If the user is not authenticated, show a login form
        return <Login handleLogin={handleLogin}/>;
    }

    // If the user is authenticated, show a button to download the content
    return (
        <div
            style={{
                padding: "64px",
                background: '#E5E1E6',
                display: "flex",
                justifyContent: "center", /* center horizontally */
                margin: "20px"
            }}
        >
            <ProForm
                title="Download file"
                submitter={{
                    searchConfig: {
                        submitText: 'Download',
                    },
                    resetButtonProps: {
                        style: {
                            display: 'none',
                        },
                    },
                }}
                onFinish={async (values) => {
                    await handleDownload(values)
                }}
                layout={"horizontal"}
            >
                <ProFormText
                    width="md"
                    name="secretId"
                    label="Secret ID"
                    initialValue={secretId}
                    disabled={true}
                    tooltip="ID of the Secret"
                    placeholder={"Secret ID"}
                />

                <ProFormText
                    width="md"
                    name="accessKey"
                    label="Access Key"
                    initialValue={accessKey}
                    disabled={true}
                    tooltip="Access key of the secret."
                    placeholder={"Access key"}
                />

            </ProForm>
        </div>
    );
};

export default DownloadButton;