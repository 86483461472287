import {LoginForm, ProFormText,} from '@ant-design/pro-components';
import {LockOutlined, UserOutlined,} from '@ant-design/icons';
import "./Login.css"

const Login = (props) => {

    return (
        <div className='login-dark'>
            <LoginForm
                contentStyle={{
                    minWidth: 280,
                    maxWidth: '75vw',
                    marginTop: "64px",
                }}
                title="Log in"
                initialValues={{
                    autoLogin: true,
                }}
                submitter={
                    {
                        searchConfig: {
                            submitText: "Sign In"                
                        },
                    }
                }
                onFinish={async (values) => {
                    await props.handleLogin(values);
                }}
            >
                <ProFormText
                    labelAlign='right'
                    label="Email"
                    name="email"
                    fieldProps={{
                        size: 'large',
                        prefix: <UserOutlined/>,
                    }}
                    placeholder="Email"
                    rules={[
                        {
                            required: true,
                            message: "Please input email.",
                        },
                        {
                            type: 'email',
                            message: 'The input is not valid E-mail!',
                        }
                    ]}
                />
                <ProFormText.Password
                    label="Password"
                    name="password"
                    fieldProps={{
                        size: 'large',
                        prefix: <LockOutlined/>,
                    }}
                    placeholder="Password"
                    rules={[
                        {
                            required: true,
                            message: "Please input password",
                        },
                    ]}
                />
            </LoginForm>
        </div>
    );
}

export default Login;